import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import Aboutback from "../components/aboutbg" // Tell Webpack this JS file uses this image
import Homebg from "../images/homebg.jpg"

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);


  const Homeback = {
    backgroundAttachment: 'fixed',
    backgroundImage: 'url('+ Homebg+')'
    }


const Solutions = () => (
  <Layout>
    <Seo title="Operation Theater - Anesthesia Machine - Operation Light " description="AFRICORP HEALTHCARE has garnered extensive knowledge of the local environment ensuring its clients benefit from our experience and succeed in the environments in which they operate. We design complete TURN-KEY solutions that work for you, smoothening logistic processes and maximizing your business outcomes." />
    <ThemeProvider theme={theme}>
    <Aboutback title="Solutions" />

   <div style={Homeback}>
        <div style={{ backgroundColor:'rgba(236, 236, 236, 0.68)' }}>
   <Container>
     <Box pt={4} pb={5}>
     <Grid container>
       <Grid item xs={12}>
       <Typography gutterBottom paragraph variant="body1">
       Our Group also offers feasible solutions to our partners enhancing their capabilities by conducting
       </Typography>
       <List>
  <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>Complete TURNKEY projects (Healthcare) </Typography></ListItem>
  <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>Supply of Biomedical equipment's & Instruments </Typography></ListItem>
  <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>Setting up a complete Radiology department includes X-rays, CT & MRI , </Typography></ListItem>
  <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>Operation Room / Modular Theatre / Specialized surgery room </Typography></ListItem>
  <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>Different types of Intensive Care Units (ICU’s) </Typography></ListItem>
  <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>Complete Cardiology facility including CathLab </Typography></ListItem>
</List> 
<Typography gutterBottom paragraph variant="body1">
Over the decades of market knowledge & experience in Africa, Middle East & Asian countries, AFRICORP HEALTHCARE has garnered extensive knowledge of the local environment ensuring its clients benefit from our experience and succeed in the environments in which they operate. We design complete TURN-KEY solutions that work for you, smoothening logistic processes and maximizing your business outcomes.
</Typography>
<Typography gutterBottom paragraph variant="body1">
Long-term strategic partnerships enable us to find the answers to the challenges our customers are facing together
</Typography>
<Typography gutterBottom paragraph variant="body1">
Delivering innovation that matters to you
</Typography>

<Hidden mdUp>
    <Box mb={4}></Box>
    </Hidden>
       </Grid>
     </Grid>
     </Box>
   </Container>
   </div>
   </div>
  


    </ThemeProvider>
  </Layout>
)

export default Solutions
